export function russianPlural(n: number, choicesLength: number) {
  const isTeen = n % 100 > 10 && n % 100 < 20

  const isZero = n === 0
  const isOne = !isTeen && n % 10 === 1
  const isFew = !isTeen && n % 10 >= 2 && n % 10 <= 4

  if (choicesLength >= 4) {
    if (isZero) return 0
    if (isOne) return 1
    if (isFew) return 2
    return 3
  }

  if (choicesLength === 3) {
    if (isOne) return 0
    if (isFew) return 1
    return 2
  }

  if (choicesLength === 2) {
    return isOne ? 0 : 1
  }

  return 0
}
