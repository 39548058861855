import en from './locales/en.json'
import ru from './locales/ru.json'
import pt from './locales/pt.json'
import es from './locales/es.json'

import { russianPlural } from './russianPlural'

export default defineI18nConfig(() => ({
  legacy: false,
  lazy: true,
  messages: {
    en,
    ru,
    pt,
    es,
  },
  pluralRules: {
    ru: russianPlural,
  },
  pluralizationRules: {
    ru: russianPlural,
  },
}))
